import React, { useState } from 'react';

import type { StreamableRide } from '@/types';

import * as Popover from '@radix-ui/react-popover';
import { useFlags } from 'launchdarkly-react-client-sdk';

import CheckboxItem from '@/features/RidesTable/components/Filters/CheckboxItem';
import {
  selectSelectedRides,
  setAllRidesSelected,
  setSelectedRides,
} from '@/features/RidesTable/store/selectedRidesSlice';

import './main.scss';

import { useDispatch, useSelector } from 'react-redux';

const BulkActionCell: React.FC<{
  label: string;
  ride: StreamableRide;
}> = ({ label, ride }) => {
  const { reactDashboardBulkActions = false } = useFlags();
  const dispatch = useDispatch();
  const selectedRides = useSelector(selectSelectedRides);

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleChecked = (rideId: number) => {
    if (selectedRides.includes(rideId)) {
      const filteredRides = selectedRides.filter((r) => r !== rideId);
      dispatch(setSelectedRides(filteredRides));
      dispatch(setAllRidesSelected(false));
    } else {
      const newRides = [...selectedRides, rideId];
      dispatch(setSelectedRides(newRides));
    }
  };

  return (
    <td className="bulk-action" data-label={label} aria-label={label}>
      {reactDashboardBulkActions && (
        <div className="bulk-action">
          <Popover.Root open={openDropdown} onOpenChange={setOpenDropdown}>
            <Popover.Trigger asChild>
              <button
                className="IconButton"
                aria-label="Customise options"
                type="button"
              >
                <CheckboxItem
                  text=""
                  checked={selectedRides.includes(ride.id)}
                  onCheckedChange={() => handleChecked(ride.id)}
                />
              </button>
            </Popover.Trigger>
          </Popover.Root>
        </div>
      )}
    </td>
  );
};

export default BulkActionCell;
