/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import './Pagination.scss';

import PerPageDropdown from './PerPageDropDown/PerPageDropdown';

const Pagination: React.FC<{
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  total: number;
}> = ({ rowsPerPage = 10, total, setPage, page }) => {
  if (total <= 0) {
    return null; // Do not render pagination on empty rides table
  }
  // create a page list to display all the pages?
  const prevPage = page - 1;
  const nextPage = page + 1;
  const finalPage = Math.ceil(total / rowsPerPage);

  const currPageEnd = rowsPerPage * page;
  const end = currPageEnd <= total ? currPageEnd : total; // Ensure we don't go over
  const start = total > 0 ? (page - 1) * rowsPerPage + 1 : 0;

  const shouldShowPrev = page > 1;
  const shouldShowNext = end < total;

  const first = () => setPage(1);
  const prev = () => setPage(prevPage);
  const next = () => setPage(nextPage);
  const last = () => setPage(finalPage);

  return (
    <div className="pagination">
      <div className="spacer flex-column flex-sm-row" style={{ gap: '1rem' }}>
        <div className="pagination-info">
          <div className="pageLimit">
            <PerPageDropdown rowsPerPage={rowsPerPage} />
          </div>
          <div className="pagination-note">
            Showing {start} - {end} of {total}
          </div>
        </div>
        <ul className="pagination-items">
          {shouldShowPrev && (
            <>
              <li className="pagination-item -step">
                <button
                  className="pagination-link"
                  type="button"
                  onClick={first}
                >
                  First{' '}
                </button>
              </li>
              <li className="pagination-item -step">
                <button
                  className="pagination-link"
                  type="button"
                  onClick={prev}
                >
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M5.24675 7.50989C4.91775 7.83889 4.91775 8.38689 5.24675 8.72189L9.61375 13.0829C9.94275 13.4119 10.4917 13.4119 10.8267 13.0829L11.3288 12.5809C11.6578 12.2459 11.6578 11.6969 11.3288 11.3679L8.07375 8.11289L11.3288 4.86389C11.6578 4.52889 11.6578 3.97989 11.3288 3.65189L10.8267 3.14889C10.5 3 9.94275 2.81389 9.61375 3.14889L5.24675 7.50989Z"
                      fillRule="evenodd"
                    />
                  </svg>
                  Prev
                </button>
              </li>{' '}
              <li className="pagination-item">
                <button
                  className="pagination-link"
                  type="button"
                  onClick={prev}
                >
                  {prevPage}
                </button>
              </li>
            </>
          )}
          <li className="pagination-item">
            <button
              className="pagination-link is-active"
              type="button"
              onClick={() => null}
            >
              {page}
            </button>
          </li>{' '}
          {shouldShowNext && (
            <>
              <li className="pagination-item">
                <button
                  className="pagination-link"
                  type="button"
                  onClick={next}
                >
                  {nextPage}
                </button>
              </li>{' '}
              <li className="pagination-item -step">
                <button
                  className="pagination-link"
                  type="button"
                  onClick={next}
                >
                  Next{' '}
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M11.3284 7.50989C11.6574 7.83889 11.6574 8.38689 11.3284 8.72189L6.96145 13.0829C6.63245 13.4119 6.08345 13.4119 5.74845 13.0829L5.24645 12.5809C4.91745 12.2459 4.91745 11.6969 5.24645 11.3679L8.50145 8.11289L5.24645 4.86389C4.91745 4.52889 4.91745 3.97989 5.24645 3.65189L5.74845 3.14889C5.35245 3.14889 6.63245 2.81389 6.96145 3.14889L11.3284 7.50989Z"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              </li>
              <li className="pagination-item -step">
                <button
                  className="pagination-link"
                  type="button"
                  onClick={last}
                >
                  Last{' '}
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
