/* eslint-disable no-param-reassign */
import type { RootState } from '@/store';
import type { AssignedStatus, AvailableStatus, VehicleType } from '@/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RideFilterState } from '../types';

import {
  createListenerMiddleware,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { isCommunity, isScheduled } from '@/path_defs';
import {
  DEFAULT_COMMUNITY_STATUSES,
  DEFAULT_SCHEDULED_STATUSES,
  VEHICLE_TYPES,
} from '@/types';

dayjs.extend(utc);

interface SetPagePayload {
  page: number;
}

export interface DispatcherRideFilterState extends RideFilterState {
  assignedStatus: AssignedStatus[];
  availableStatus: AvailableStatus[];
}

const params = new URLSearchParams(window.location.search);

const page = parseInt(params.get('page') || '1', 10);

let willCall: boolean | null = null;
if (localStorage.getItem('ridesTableFilters/setShowWillCall') === '0') {
  willCall = false;
}

const startTime = dayjs().startOf('day');
const endTime = startTime.add(7, 'day').endOf('day');

const search = params.get('search') || '';
const vehicleTypes =
  (localStorage
    .getItem('ridesTableFilters/setVehicleTypes')
    ?.split(',') as VehicleType[]) || Object.values(VEHICLE_TYPES);

const assignedStatus =
  (localStorage
    .getItem('ridesTableFilters/setAssignedStatuses')
    ?.split(',') as AssignedStatus[]) || DEFAULT_SCHEDULED_STATUSES;

const availableStatus =
  (localStorage
    .getItem('ridesTableFilters/setAvailableStatuses')
    ?.split(',') as AvailableStatus[]) || DEFAULT_COMMUNITY_STATUSES;

const items = parseInt(
  localStorage.getItem('ridesTableFilters/setItemsPerPage') || '10',
  10,
);

const radius =
  parseInt(localStorage.getItem('ridesTableFilters/setRadius'), 10) || null;

const isInitState = ({
  willCall,
  vehicleTypes,
  radius,
  assignedStatus,
  availableStatus,
  hospitalIds = [],
}: DispatcherRideFilterState) => {
  if (willCall === false) {
    return false;
  }

  if (radius !== null) {
    return false;
  }

  if (hospitalIds.length !== 0) {
    return false;
  }

  if (!Object.values(VEHICLE_TYPES).every((k) => vehicleTypes.includes(k))) {
    return false;
  }

  if (
    isScheduled() &&
    assignedStatus[0] !== 'completed' &&
    assignedStatus[0] !== 'canceled' &&
    !DEFAULT_SCHEDULED_STATUSES.every((k) => assignedStatus.includes(k))
  ) {
    return false;
  }

  if (
    isCommunity() &&
    availableStatus[0] !== 'declined' &&
    !DEFAULT_COMMUNITY_STATUSES.every((k) => availableStatus.includes(k))
  ) {
    return false;
  }

  return true;
};

const isInit = isInitState({
  vehicleTypes, // TODO: Remove after making vehicleTypes dynamic
  willCall,
  radius,
  assignedStatus,
  availableStatus,
} as DispatcherRideFilterState);

const initialState: DispatcherRideFilterState = {
  page,
  items,
  willCall,
  startTime: startTime.utc().format(),
  endTime: endTime.utc().format(),
  search,
  vehicleTypes,
  hospitalIds: [],
  assignedStatus,
  availableStatus,
  radius,
  isInit,
  sortField: localStorage.getItem('ridesTableFilters/setSortField'),
  sortType: localStorage.getItem('ridesTableFilters/setSortType'),
};

const slice = createSlice({
  name: 'ridesTableFilters',
  initialState,
  reducers: {
    // for all actions except setPage, page state is 1 to avoid using the wrong page total when switching between filters/tabs
    setShowWillCall: (state, action: PayloadAction<boolean | null>) => {
      state.willCall = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.page = 1;
    },
    setVehicleTypes: (state, action: PayloadAction<VehicleType[]>) => {
      state.vehicleTypes = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setHospitals: (state, action: PayloadAction<number[]>) => {
      state.hospitalIds = action.payload.filter((a) => a !== null);
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setAssignedStatuses: (state, action: PayloadAction<AssignedStatus[]>) => {
      state.assignedStatus = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setAvailableStatuses: (state, action: PayloadAction<AvailableStatus[]>) => {
      state.availableStatus = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
      state.page = 1;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endTime = action.payload;
      state.page = 1;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      state.page = action.payload.page;
    },
    setItemsPerPage: (state, action: PayloadAction<number>) => {
      state.items = action.payload;
      state.page = 1;
    },
    setRadius: (state, action: PayloadAction<number | null>) => {
      state.radius = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    resetFilters: (state) => {
      if (
        state.assignedStatus[0] !== 'completed' &&
        state.assignedStatus[0] !== 'canceled' &&
        !DEFAULT_SCHEDULED_STATUSES.every((k) =>
          state.assignedStatus.includes(k),
        )
      ) {
        state.assignedStatus = DEFAULT_SCHEDULED_STATUSES;
      }

      if (
        state.availableStatus[0] !== 'declined' &&
        !DEFAULT_COMMUNITY_STATUSES.every((k) =>
          state.availableStatus.includes(k),
        )
      ) {
        state.availableStatus = DEFAULT_COMMUNITY_STATUSES;
      }

      state.hospitalIds = [];
      state.radius = null;
      state.vehicleTypes = Object.values(VEHICLE_TYPES);
      state.willCall = null;
      state.isInit = true;
      state.page = 1;
    },
    setSortField: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
      state.page = 1;
    },
    setSortType: (state, action: PayloadAction<string | null>) => {
      state.sortType = action.payload;
      state.page = 1;
    },
  },
});

export const selectAssignedStatuses = (state: RootState) =>
  state.ridesTableFilters.assignedStatus;
export const selectAvailableStatuses = (state: RootState) =>
  state.ridesTableFilters.availableStatus;
export const selectShowWillCall = (state: RootState) =>
  state.ridesTableFilters.willCall;
export const selectVehicleTypes = (state: RootState) =>
  state.ridesTableFilters.vehicleTypes;
export const selectRideFilters = (state: RootState) => state.ridesTableFilters;
export const selectPage = (state: RootState) => state.ridesTableFilters.page;
export const selectItemsPerPage = (state: RootState) =>
  state.ridesTableFilters.items;
export const selectSortField = (state: RootState) =>
  state.ridesTableFilters.sortField;
export const selectSortType = (state: RootState) =>
  state.ridesTableFilters.sortType;
export const selectIsInit = (state: RootState) =>
  state.ridesTableFilters.isInit;
export const selectStartTime = (state: RootState) =>
  state.ridesTableFilters.startTime;
export const selectEndTime = (state: RootState) =>
  state.ridesTableFilters.endTime;
export const selectSearch = (state: RootState) =>
  state.ridesTableFilters.search;
export const selectHospitals = (state: RootState) =>
  state.ridesTableFilters.hospitalIds;
export const selectRadius = (state: RootState) =>
  state.ridesTableFilters.radius;

export const {
  setShowWillCall,
  setHospitals,
  setAssignedStatuses,
  setAvailableStatuses,
  setSearch,
  setStartDate,
  setEndDate,
  setPage,
  setItemsPerPage,
  setVehicleTypes,
  setSortField,
  setSortType,
  setRadius,
  resetFilters,
} = slice.actions;

export const ridesTableFilters = slice.reducer;

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isAnyOf(
    setShowWillCall,
    setVehicleTypes,
    setSortField,
    setSortType,
    setAssignedStatuses,
    setAvailableStatuses,
    setItemsPerPage,
    setRadius,
    resetFilters,
  ),
  effect: ({ type, payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if (type === 'ridesTableFilters/setShowWillCall') {
      if (payload === null) {
        localStorage.removeItem(type as string);
      } else {
        localStorage.setItem(type as string, `${Number(payload)}`);
      }
    }

    if (
      type === 'ridesTableFilters/setVehicleTypes' ||
      type === 'ridesTableFilters/setAssignedStatuses' ||
      type === 'ridesTableFilters/setAvailableStatuses'
    ) {
      localStorage.setItem(type as string, (payload as string[]).join(','));
    }

    if (
      type === 'ridesTableFilters/setSortField' ||
      type === 'ridesTableFilters/setSortType' ||
      type === 'ridesTableFilters/setItemsPerPage' ||
      type === 'ridesTableFilters/setRadius'
    ) {
      localStorage.setItem(type as string, payload as string);
    }

    if (type === 'ridesTableFilters/resetFilters') {
      localStorage.removeItem('ridesTableFilters/setShowWillCall');
      localStorage.removeItem('ridesTableFilters/setVehicleTypes');
      localStorage.removeItem('ridesTableFilters/setAssignedStatuses');
      localStorage.removeItem('ridesTableFilters/setAvailableStatuses');
      localStorage.removeItem('ridesTableFilters/setRadius');
    }
  },
});
