/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import type { RideBookerFacet } from '@/types';

import InfoIcon from '@assets/images/icons/Info.svg';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { useDispatch, useSelector } from 'react-redux';

import * as Popover from '@/lib/@radix-ui/react-popover';
import * as Tooltip from '@/lib/@radix-ui/react-tooltip';

import { useTableContext } from '../../providers/TableProvider';
import {
  selectRideFilters,
  setRideBookers,
} from '../../store/cc/ridesFilterSlice';
import CheckboxItem from './CheckboxItem';

const label = 'Ride Bookers';

const buildInitialState = (bookers: RideBookerFacet[], selected: number[]) => {
  const initialState: { [key: number]: number | null } = {};
  if (selected.length === 0) {
    return initialState;
  }

  selected.forEach((id) => {
    initialState[id] = null;
  });

  bookers.forEach(({ id }) => {
    if (!selected.includes(id)) {
      initialState[id] = id;
    }
  });

  return initialState;
};

const getSelectedBookers = (
  bookers: RideBookerFacet[],
  bookerIds: {
    [key: number]: number | null;
  },
) => bookers.filter(({ id }) => !bookerIds[id]).map(({ id }) => id);

const getLabel = (bookers: RideBookerFacet[], selected: number[]) => {
  if (selected.length === bookers.length) {
    return label;
  }

  if (selected.length === 1) {
    return `${label}: 1 booker`;
  }

  return `${label}: ${selected.length} bookers`;
};

const fullName = ({ firstName, lastName }: RideBookerFacet) =>
  `${firstName} ${lastName}`;

const RideBookers = () => {
  const dispatch = useDispatch();
  const { rideBookerIds, isInit } = useSelector(selectRideFilters);
  const { rideBookers } = useTableContext();

  const [searchText, setSearchText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  // Selected bookers are absent from the object or have a null value i.e. { 1: null }.
  const [deselectedBookerIds, setDelectedBookerIds] = useState(
    buildInitialState(rideBookers, rideBookerIds),
  );

  useEffect(() => {
    if (isInit) {
      setDelectedBookerIds({});
    }
  }, [isInit]);

  useEffect(() => {
    dispatch(setRideBookers([]));
  }, []);

  const handleApply = () => {
    let selected = getSelectedBookers(rideBookers, deselectedBookerIds);

    /**
     * `ridesFilterSlice` checks if `selectedBookers` is an empty array when determining
     * the `isInit` value. If a user selects a checkbox item and then deselects it,
     * set the filter state to an empty array instead of the  full list.
     */
    selected = selected.length === rideBookers.length ? [] : selected;

    dispatch(setRideBookers(selected));
  };

  const handleCheck = (id: number) => {
    setDelectedBookerIds({
      ...deselectedBookerIds,
      [id]: deselectedBookerIds[id] ? null : id,
    });
  };

  const filterListByText = (list: RideBookerFacet[]) => {
    return list.filter((booker) =>
      fullName(booker)
        .toLocaleUpperCase()
        .includes(searchText.toLocaleUpperCase()),
    );
  };

  const bookersFilteredByText = filterListByText(rideBookers);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <span className="filter-label">
          {getLabel(
            rideBookers,
            getSelectedBookers(rideBookers, deselectedBookerIds),
          )}
          <ChevronDownIcon />
        </span>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className="popover-content py-3 px-0" align="start">
          <div className="popover-top d-flex px-4 flex-column mb-0">
            <label className="popover-title" htmlFor="facility-search">
              <div
                className="d-flex flex-items-center"
                style={{ gap: '0.25rem' }}
              >
                Filter ride bookers
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <div className="IconButton">
                        <InfoIcon />
                      </div>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="TooltipContent"
                        sideOffset={2}
                      >
                        List displays ride bookers actively scheduling rides.
                        <Tooltip.Arrow className="TooltipArrow" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
            </label>
            <div className={`styled-input ${isFocused ? 'focused' : ''}`}>
              <div className="d-flex pl-2">
                <MagnifyingGlassIcon />
              </div>
              <div className="d-flex flex-1 flex-self-stretch">
                <input
                  placeholder="Find by name"
                  className="styled-input__field"
                  id="facility-search"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchText(event.target.value)
                  }
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  value={searchText}
                />
              </div>
            </div>
          </div>
          <div className="popover-scrollist py-3 px-4">
            {bookersFilteredByText.length > 0 ? (
              bookersFilteredByText.map((booker) => (
                <CheckboxItem
                  text={fullName(booker)}
                  key={booker.id}
                  onCheckedChange={() => handleCheck(booker.id)}
                  checked={!deselectedBookerIds[booker.id]}
                />
              ))
            ) : (
              <div className="empty-state py-4 -no-border">
                No results found.
              </div>
            )}
          </div>

          <div className="popover-actions d-flex flex-justify-end px-4 mt-0">
            <button
              className="button -small -no-background"
              onClick={() => setOpen(false)}
              type="button"
            >
              Cancel
            </button>
            <button
              className="button -small -primary"
              onClick={handleApply}
              type="button"
            >
              Apply
            </button>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default RideBookers;
