/* eslint-disable max-len */
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkMiddleware } from 'redux-thunk';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import {
  listenerMiddleware as ccListener,
  ridesTableFilters as ccRidesTableFilters,
} from '@/features/RidesTable/store/cc/ridesFilterSlice';
import {
  listenerMiddleware as dispatcherListener,
  ridesTableFilters,
} from '@/features/RidesTable/store/ridesFilterSlice';
import { selectedRides } from '@/features/RidesTable/store/selectedRidesSlice';
import { userColumnPreferences } from '@/features/RidesTable/store/userColumnPreferencesSlice';

import { roundtripBaseApiV3 } from '../lib/rtk-query';
import auth from './authSlice';
import flashMessages from './flashMessagesSlice';
import toastNotifications from './toastNotificationsSlice';

const middlewares: Array<ThunkMiddleware> = [
  ccListener.middleware,
  dispatcherListener.middleware,
];

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  toastNotifications,
  flashMessages,
  [roundtripBaseApiV3.reducerPath]: roundtripBaseApiV3.reducer,
  auth,
  ridesTableFilters,
  selectedRides,
  ccRidesTableFilters,
  userColumnPreferences,
});

export type RootState = ReturnType<typeof rootReducer>;

const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(middlewares)
        .concat(roundtripBaseApiV3.middleware),
    reducer: rootReducer,
    preloadedState,
  });

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// Export a hook that can be reused to resolve types
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default setupStore;
