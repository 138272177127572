import React from 'react';

import type { CellComponent } from '@/features/RidesTable/types';

import Cell from '@/features/RidesTable/components/Table/Cell';

const CancelationReasonCell: CellComponent = ({ ride, href }) => (
  <Cell
    label="Cancelation Reason"
    tdClass="patient"
    href={typeof href === 'function' ? href(ride) : href}
    id={`cancelation-reason-${ride.id}`}
  >
    <strong className="fs-block">{ride.cancelationReason}</strong>
    <br />
  </Cell>
);

export default CancelationReasonCell;
