import React from 'react';

import { CheckIcon } from '@radix-ui/react-icons';

import * as Checkbox from '@/lib/@radix-ui/react-checkbox';

const CheckboxItem = ({
  text,
  checked,
  onCheckedChange,
}: {
  checked: boolean;
  onCheckedChange: Checkbox.CheckboxProps['onCheckedChange'];
  text?: string;
}) => {
  const id = text?.toLowerCase().replace(/ /g, '-');

  return (
    <div className="d-flex dropdown-option">
      <Checkbox.Root
        className="CheckboxRoot"
        checked={checked}
        onCheckedChange={onCheckedChange}
        id={id}
      >
        <Checkbox.Indicator className="CheckboxIndicator">
          <CheckIcon />
        </Checkbox.Indicator>
      </Checkbox.Root>

      {text && (
        <label className="Label flex-1" htmlFor={id}>
          {text}
        </label>
      )}
    </div>
  );
};

export default CheckboxItem;
