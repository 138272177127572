import React from 'react';

import type { ToastActions } from '@/types';

import { useDispatch, useSelector } from 'react-redux';

import GenericSuccessAction from '@/components/Toasts/GenericSuccessAction';
import RefreshAction from '@/components/Toasts/RefreshAction';
import ViewRideAction from '@/components/Toasts/ViewRideAction';
import * as Toast from '@/lib/@radix-ui/react-toast';
import {
  popToastNotification,
  selectNotifications,
} from '@/store/toastNotificationsSlice';

interface Props {
  children: React.ReactNode;
}

const ToastProvider = ({ children }: Props) => {
  const dispatch = useDispatch();

  const notifications = useSelector(selectNotifications) || [];
  const notification = notifications[notifications.length - 1];

  const Action = ((action?: ToastActions) => {
    switch (action) {
      case 'refresh': {
        return RefreshAction;
      }
      case 'view ride': {
        return () => ViewRideAction(notification.rideId as number);
      }
      case 'success': {
        return GenericSuccessAction;
      }
      default:
        return () => null;
    }
  })(notification?.action);

  return (
    <Toast.Provider>
      {notification && (
        <Toast.Root
          className={`ToastRoot ${notification?.action}`}
          key={notifications.indexOf(notification)}
          duration={3000}
          onOpenChange={(state) => {
            if (state === false) {
              dispatch(
                popToastNotification({
                  text: notification?.text,
                }),
              );
            }
          }}
        >
          <Toast.Title className="ToastTitle">{notification?.text}</Toast.Title>

          <Action />
        </Toast.Root>
      )}

      <Toast.Viewport className="ToastViewport" />

      {children}
    </Toast.Provider>
  );
};

export default ToastProvider;
