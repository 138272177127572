/* eslint-disable no-param-reassign */
import type { RootState } from '@/store';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export interface SelectedRidesState {
  allRidesSelected: boolean;
  selectedRides: number[];
}

const initialState: SelectedRidesState = {
  selectedRides: [],
  allRidesSelected: false,
};

const slice = createSlice({
  name: 'selectedRides',
  initialState,
  reducers: {
    setSelectedRides: (state, action: PayloadAction<number[]>) => {
      state.selectedRides = action.payload;
    },
    setAllRidesSelected: (state, action: PayloadAction<boolean>) => {
      state.allRidesSelected = action.payload;
    },
  },
});

export const selectSelectedRides = (state: RootState) =>
  state.selectedRides.selectedRides;

export const selectAllRidesSelected = (state: RootState) =>
  state.selectedRides.allRidesSelected;

export const { setSelectedRides, setAllRidesSelected } = slice.actions;

export const selectedRides = slice.reducer;
