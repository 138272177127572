import React from 'react';

import type { RideStatusQueryParams } from '@/types';
import type { MouseEventHandler } from 'react';

import useRideCount from '../../hooks/useRideCount';

const CountIcon = ({ count }: { count: number }) =>
  count ? <span className="indicator-circle">{count}</span> : null;

// TODO: Better name?
const CountFetcher = ({
  status,
  search,
  endTime,
  startTime,
}: {
  endTime: string;
  search: string;
  startTime: string;
  status: RideStatusQueryParams;
}) => {
  const { count } = useRideCount(status, search, endTime, startTime);

  return <CountIcon count={count} />;
};

const StatusButton = ({
  active,
  children,
  count,
  href = '#',
  onClick,
  status,
  search,
  endTime,
  startTime,
}: {
  active: boolean;
  children: React.ReactNode;
  count?: number;
  endTime: string;
  href?: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  search: string;
  startTime: string;
  status: RideStatusQueryParams;
}) => {
  return (
    <a
      href={`${href}`}
      className={`toggle-option ${active ? 'is-active' : null}`}
      onClick={onClick}
    >
      {children}

      {status && (
        <CountFetcher
          status={status}
          search={search}
          startTime={startTime}
          endTime={endTime}
        />
      )}
      {count && <CountIcon count={count} />}
    </a>
  );
};

export default StatusButton;
