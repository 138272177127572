import React from 'react';

import type { Ride, StreamableRide } from '@/types';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import DefaultHeader from './Header';

import './styles.scss';

import type { RideColumn } from '../../types';

import { selectSelectedRides } from '../../store/selectedRidesSlice';

const Row: React.FC<{
  columns: RideColumn[];
  ride: StreamableRide;
  type: string;
}> = ({ ride, type, columns }) => {
  const selectedRides = useSelector(selectSelectedRides);

  return (
    <tr
      className={`dispatcher-community ${ride.streamed ? '-yellow' : ''}`}
      id={`ride-${ride.id}`}
      data-ride_id={ride.id}
      data-status={ride.currentStatus}
      key={ride.id}
      style={{
        backgroundColor: selectedRides.includes(ride.id) ? '#ebfdff' : '',
      }}
    >
      {columns.map(({ Cell, label, href }) => (
        <Cell
          ride={ride}
          key={`${label} ${ride.id}`}
          type={type}
          href={href}
          label={label}
        />
      ))}
    </tr>
  );
};

const Table: React.FC<{
  columns: RideColumn[];
  rides: Ride[];
  type: string;
}> = ({ type, rides, columns }) => {
  const { refactoredStatusCell } = useFlags();

  /**
   * Wait for flag to resolve before mounting counter(s).
   */
  if (refactoredStatusCell === undefined) {
    return null;
  }

  return (
    <table
      className={`table ${rides.length === 0 ? 'hide' : ''}`}
      id="react-patient-rides-table"
    >
      <thead>
        <tr>
          {columns.map(({ label, sortKey, Header = DefaultHeader }) => (
            <Header label={label} sortKey={sortKey} key={sortKey} />
          ))}
        </tr>
      </thead>

      <tbody id={`dispatcher-${type}-rides-table-react`}>
        {rides.map((ride) => (
          <Row ride={ride} key={ride.id} type={type} columns={columns} />
        ))}
      </tbody>
    </table>
  );
};

export default Table;
