import React from 'react';

import { useSelector } from 'react-redux';

import { selectSelectedRides } from '@/features/RidesTable/store/selectedRidesSlice';

import BulkCompleteOption from './BulkCompleteOption';
import BulkDeclineOption from './BulkDeclineOption';
import BulkReleaseOption from './BulkReleaseOption';

const Root: React.FC<{
  children: React.ReactNode;
  visible?: boolean;
}> = ({ children, visible = true }) => {
  const selectedRidesQuantity = useSelector(selectSelectedRides).length;

  return (
    <div
      className={`${visible ? `d-flex` : `d-none`}
           flex-column flex-sm-row flex-justify-start mb-4`}
      style={{ overflowX: 'auto' }}
    >
      <span className="filter-label">
        {`${selectedRidesQuantity} Ride${selectedRidesQuantity === 1 ? '' : 's'} selected`}
      </span>
      {children}
    </div>
  );
};

const BulkActionHeaderBar = {
  Root,
  BulkDeclineOption,
  BulkReleaseOption,
  BulkCompleteOption,
};

export default BulkActionHeaderBar;
