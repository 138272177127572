/* eslint-disable no-param-reassign */
import type { RootState } from '@/store';
import type { AuthStatus, CcRideStatusBadges, VehicleType } from '@/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RideFilterState } from '../../types';

import {
  createListenerMiddleware,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  DEFAULT_CC_FILTER_STATUSES,
  RIDE_AUTH_STATUS,
  VEHICLE_TYPES,
} from '@/types';

dayjs.extend(utc);

export interface CcRideFilterState extends RideFilterState {
  authStatuses: AuthStatus[];
  rideBookerIds: number[];
  statusBadge: CcRideStatusBadges[];
}

const page = 1;

let willCall: boolean | null = null;
if (localStorage.getItem('cc/ridesTableFilters/setShowWillCall') === '0') {
  willCall = false;
}

const startTime = dayjs().startOf('day');
const endTime = startTime.add(7, 'day').endOf('day');

const search = '';
const vehicleTypes =
  (localStorage
    .getItem('cc/ridesTableFilters/setVehicleTypes')
    ?.split(',') as VehicleType[]) || Object.values(VEHICLE_TYPES);

const authStatuses =
  (localStorage
    .getItem('cc/ridesTableFilters/setAuthStatuses')
    ?.split(',') as AuthStatus[]) || Object.values(RIDE_AUTH_STATUS);

const statusBadge =
  (localStorage
    .getItem('cc/ridesTableFilters/setStatusBadge')
    ?.split(',') as CcRideStatusBadges[]) || DEFAULT_CC_FILTER_STATUSES;

const items = parseInt(
  localStorage.getItem('cc/ridesTableFilters/setItemsPerPage') || '10',
  10,
);

const radius =
  parseInt(localStorage.getItem('cc/ridesTableFilters/setRadius') || '0', 10) ||
  null;

const isInitState = ({
  /* eslint-disable @typescript-eslint/no-shadow */
  authStatuses,
  willCall,
  vehicleTypes,
  radius,
  statusBadge,
  hospitalIds = [],
  /* eslint-enable @typescript-eslint/no-shadow */
}: CcRideFilterState) => {
  if (willCall === false) {
    return false;
  }

  if (radius !== null) {
    return false;
  }

  if (hospitalIds.length !== 0) {
    return false;
  }

  if (!Object.values(VEHICLE_TYPES).every((k) => vehicleTypes.includes(k))) {
    return false;
  }

  if (!Object.values(RIDE_AUTH_STATUS).every((k) => authStatuses.includes(k))) {
    return false;
  }

  if (
    statusBadge[0] !== 'completed' &&
    statusBadge[0] !== 'canceled' &&
    !DEFAULT_CC_FILTER_STATUSES.every((k) => statusBadge.includes(k))
  ) {
    return false;
  }

  return true;
};

const isInit = isInitState({
  authStatuses,
  vehicleTypes,
  willCall,
  radius,
  statusBadge,
} as CcRideFilterState);

const initialState: CcRideFilterState = {
  authStatuses,
  page,
  items,
  willCall,
  startTime: startTime.utc().format(),
  endTime: endTime.utc().format(),
  search,
  vehicleTypes,
  hospitalIds: [],
  rideBookerIds: [],
  statusBadge,
  radius,
  isInit,
  sortField: localStorage.getItem('cc/ridesTableFilters/setSortField'),
  sortType: localStorage.getItem('cc/ridesTableFilters/setSortType'),
};

const slice = createSlice({
  name: 'cc/ridesTableFilters',
  initialState,
  reducers: {
    // for all actions except setPage, page state is 1 to avoid using the wrong page total when switching between filters/tabs
    setShowWillCall: (state, action: PayloadAction<boolean | null>) => {
      state.willCall = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.page = 1;
    },
    setVehicleTypes: (state, action: PayloadAction<VehicleType[]>) => {
      state.vehicleTypes = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setAuthStatuses: (state, action: PayloadAction<AuthStatus[]>) => {
      state.authStatuses = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setHospitals: (state, action: PayloadAction<number[]>) => {
      state.hospitalIds = action.payload.filter((a) => a !== null);
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setRideBookers: (state, action: PayloadAction<number[]>) => {
      state.rideBookerIds = action.payload.filter((a) => a !== null);
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setStatusBadge: (state, action: PayloadAction<CcRideStatusBadges[]>) => {
      state.statusBadge = action.payload;
      state.isInit = isInitState(state);
      state.page = 1;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
      state.page = 1;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endTime = action.payload;
      state.page = 1;
    },
    setPage: (state, action: PayloadAction<{ page: number }>) => {
      state.page = action.payload.page;
    },
    setItemsPerPage: (state, action: PayloadAction<number>) => {
      state.items = action.payload;
      state.page = 1;
    },
    resetFilters: (state) => {
      if (
        state.statusBadge[0] !== 'completed' &&
        state.statusBadge[0] !== 'canceled'
      ) {
        state.statusBadge = DEFAULT_CC_FILTER_STATUSES;
      }

      state.hospitalIds = [];
      state.rideBookerIds = [];
      state.radius = null;
      state.vehicleTypes = Object.values(VEHICLE_TYPES);
      state.authStatuses = Object.values(RIDE_AUTH_STATUS);
      state.willCall = null;
      state.isInit = true;
      state.page = 1;
    },
    setSortField: (state, action: PayloadAction<string>) => {
      state.sortField = action.payload;
      state.page = 1;
    },
    setSortType: (state, action: PayloadAction<string | null>) => {
      state.sortType = action.payload;
      state.page = 1;
    },
  },
});

export const selectRideFilters = (state: RootState) =>
  state.ccRidesTableFilters;

export const {
  setShowWillCall,
  setHospitals,
  setRideBookers,
  setSearch,
  setStartDate,
  setEndDate,
  setPage,
  setItemsPerPage,
  setAuthStatuses,
  setVehicleTypes,
  setSortField,
  setSortType,
  setStatusBadge,
  // setRadius,
  resetFilters,
} = slice.actions;

export const ridesTableFilters = slice.reducer;

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isAnyOf(
    setShowWillCall,
    setAuthStatuses,
    setVehicleTypes,
    setSortField,
    setSortType,
    setStatusBadge,
    setItemsPerPage,
    resetFilters,
  ),
  effect: ({ type, payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if (type === 'cc/ridesTableFilters/setShowWillCall') {
      if (payload === null) {
        localStorage.removeItem(type as string);
      } else {
        localStorage.setItem(type as string, `${Number(payload)}`);
      }
    }

    if (
      type === 'cc/ridesTableFilters/setAuthStatuses' ||
      type === 'cc/ridesTableFilters/setVehicleTypes' ||
      type === 'cc/ridesTableFilters/setStatusBadge'
    ) {
      localStorage.setItem(type as string, (payload as string[]).join(','));
    }

    if (
      type === 'cc/ridesTableFilters/setSortField' ||
      type === 'cc/ridesTableFilters/setSortType' ||
      type === 'cc/ridesTableFilters/setItemsPerPage' ||
      type === 'cc/ridesTableFilters/setRadius'
    ) {
      localStorage.setItem(type as string, payload as string);
    }

    if (type === 'cc/ridesTableFilters/resetFilters') {
      localStorage.removeItem('cc/ridesTableFilters/setShowWillCall');
      localStorage.removeItem('cc/ridesTableFilters/setVehicleTypes');
      localStorage.removeItem('cc/ridesTableFilters/setStatusBadge');
      localStorage.removeItem('cc/ridesTableFilters/setRadius');
      localStorage.removeItem('cc/ridesTableFilters/setAuthStatuses');
    }
  },
});
