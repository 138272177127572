export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type RoundtripApiStatusCode = 'invalid_role';

export interface RoundtripApiMessage {
  description: string;
  /**
   * A custom status code defined and returned by the API. These codes rarely change at the API level
   * and can be used to decide on error handling action.
   */
  statusCode: RoundtripApiStatusCode;
}

export const US_STATES = {
  ALABAMA: 'AL',
  ALASKA: 'AK',
  'AMERICAN SAMOA': 'AS',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  'DISTRICT OF COLUMBIA': 'DC',
  'FEDERATED STATES OF MICRONESIA': 'FM',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  GUAM: 'GU',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  'MARSHALL ISLANDS': 'MH',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  'NEW YORK': 'NY',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  'NORTHERN MARIANA ISLANDS': 'MP',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PALAU: 'PW',
  PENNSYLVANIA: 'PA',
  'PUERTO RICO': 'PR',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  'VIRGIN ISLANDS': 'VI',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  'WEST VIRGINIA': 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY',
};

export interface Location {
  city: string;
  id: number;
  name: string;
  state: keyof typeof US_STATES;
  stateCode?: string;
  streetAddress: string;
  zipcode: string;
}

export type ExcludibleColumn =
  | 'vehicle'
  | 'book_time'
  | 'proximity'
  | 'trip_distance'
  | 'weight';

export type ExcludibleColumns = ExcludibleColumn[];

export const VEHICLE_TYPES = {
  als: 'als',
  bls: 'bls',
  cct: 'cct',
  medicalSedan: 'medical_sedan',
  psychTransport: 'psych_transport',
  stretcherVan: 'stretcher_van',
  taxi: 'taxi',
  wheelchair: 'wheelchair',
} as const;

export type VehicleType = (typeof VEHICLE_TYPES)[keyof typeof VEHICLE_TYPES];

export const AVAILABLE_STATUSES = {
  available: 'available',
  responded: 'responded',
  willCall: 'will_call',
  preferred: 'preferred',
  declined: 'declined',
} as const;

export type AvailableStatus =
  (typeof AVAILABLE_STATUSES)[keyof typeof AVAILABLE_STATUSES];

export const DEFAULT_COMMUNITY_STATUSES = [
  AVAILABLE_STATUSES.available,
  AVAILABLE_STATUSES.preferred,
  AVAILABLE_STATUSES.responded,
  AVAILABLE_STATUSES.willCall,
];

export const ASSIGNED_STATUSES = {
  assigned: 'assigned',
  inProgress: 'in_progress',
  willCall: 'will_call',
  submitEta: 'submit_eta',
  completed: 'completed',
  canceled: 'canceled',
} as const;

export type AssignedStatus =
  (typeof ASSIGNED_STATUSES)[keyof typeof ASSIGNED_STATUSES];

export type RideStatusQueryParams =
  | { availableStatus: AvailableStatus[] }
  | { assignedStatus: AssignedStatus[] };

export const DEFAULT_SCHEDULED_STATUSES = [
  ASSIGNED_STATUSES.assigned,
  ASSIGNED_STATUSES.inProgress,
  ASSIGNED_STATUSES.submitEta,
  ASSIGNED_STATUSES.willCall,
];

export const DISPATCHER = 'dispatcher';
export const CARE_COORDINATOR = 'care_coordinator';
export const ADMIN = 'admin';

export type Role = typeof DISPATCHER | typeof CARE_COORDINATOR | typeof ADMIN;

export type User = {
  email: string;
  hospitalId: number;
  id: number;
  role: Role;
  transportationCompanyId: number;
};

export enum TIMELINESS {
  on_time = 0,
  late_15_min,
  late_30_min,
  late_45_min,
  late_60_min,
  late_over_60_min,
}

/**
 * Roundtrip API Server ride.current_status enum values.
 */
export enum RideCurrentStatus {
  AT_DROPOFF = 'at_dropoff',
  AT_PICKUP = 'at_pickup',
  CANCELED = 'canceled',
  CLAIMED = 'claimed',
  COMPLETED = 'completed',
  INCOMPLETE = 'incompleted',
  QUEUED = 'queued',
  TO_DROPOFF = 'to_dropoff',
  TO_PICKUP = 'to_pickup',
}

export const RIDE_AUTH_STATUS = {
  authorized: 'authorized',
  authorizing: 'authorizing',
  denied: 'denied',
  moreInfoNeeded: 'more_info_needed',
  noAuthorizationRequired: 'no_authorization_required',
} as const;

export type AuthStatus =
  (typeof RIDE_AUTH_STATUS)[keyof typeof RIDE_AUTH_STATUS];

export const DECLINE_REASONS_TEXT = {
  availability: 'Availability',
  distance: 'Distance',
  payer: 'Payer',
  facility: 'Facility',
  waitingForAuthorization: 'Waiting for authorization',
  other: 'Other',
} as const;

export type DeclineReasons =
  (typeof DECLINE_REASONS_TEXT)[keyof typeof DECLINE_REASONS_TEXT];

export const CC_RIDE_STATUS_BADGES = {
  canceled: 'canceled',
  completed: 'completed',
  brokered: 'brokered',
  onHold: 'on_hold',
  willCall: 'will_call',
  incomplete: 'incomplete',
  requested: 'requested',
  findingDriver: 'finding_driver',
  claimed: 'claimed',
  findingProviders: 'finding_providers',
  checkingAvailability: 'checking_availability',
  atPickup: 'at_pickup',
  toPickup: 'to_pickup',
  atDestination: 'at_destination',
  toDestination: 'to_destination',
} as const;

export type CcRideStatusBadges =
  (typeof CC_RIDE_STATUS_BADGES)[keyof typeof CC_RIDE_STATUS_BADGES];

export const DEFAULT_CC_FILTER_STATUSES = Object.values(
  CC_RIDE_STATUS_BADGES,
).filter(
  (status) =>
    status !== CC_RIDE_STATUS_BADGES.canceled &&
    status !== CC_RIDE_STATUS_BADGES.completed,
) as CcRideStatusBadges[];

export type RideStatusBadge = CcRideStatusBadges;

export interface Ride {
  authStatus: AuthStatus;
  authorizable: boolean;
  autoAssigned: boolean;
  baseVehicleType: string;
  biddingWindow: number;
  cancelationReason: string | null;
  canceledAt: string | null;
  claimed: boolean;
  communityArrival: string | null;
  completedAt: string | null;
  createdAt: string;
  currentStatus: RideCurrentStatus;
  direction: string;
  discountedFare: number;
  distance: number;
  dropoffLocation: Location;
  dropoffLocationId: number;
  endTime: string;
  hasBid: boolean;
  id: number;
  isDeclined?: boolean;
  noAuthorizationRequired: boolean;
  otherRideId?: null;
  outsideBidWindow: boolean;
  payerTypeHumanized: string;
  pickupLocation: Location;
  pickupLocationId: number;
  ppBiddingWindow: number;
  preferredProviderArrival: string | null;
  processingRide: boolean;
  rideShareType?: null;
  rideType: string;
  rideVehicleDescription: string;
  riderFullName: string;
  rideshare: boolean;
  rideshareVehicleType: false;
  rideshareVendor?: string;
  showVehicleDetails: boolean;
  startTime: string;
  statusBadge: RideStatusBadge;
  timeliness: TIMELINESS | null;
  timerStartTime: string;
  timezone: string;
  transportationCompanyId: number;
  tripId: number;
  usesCustomizeDispatchSoftware?: null;
  vehicleDescription?: null;
  vehicleLicensePlate: null;
  vehicleNeedType: string;
  vehicleType: string;
  willCall: boolean;
}

export type StreamableRide = Ride & { streamed?: boolean; updated?: boolean };

export interface Facet {
  count: number;
  id: number;
  name: string;
}

export interface RideBookerFacet {
  count: number;
  firstName: string;
  id: number;
  lastName: string;
}

export interface RideListFacets {
  hospitals: Facet[];
  rideBookers: RideBookerFacet[];
}

export interface RideList {
  currentPage: number;
  facets: RideListFacets;
  messages?: RoundtripApiMessage[];
  pageItems: number;
  pages: number;
  rides: Ride[];
  totalCount: number;
}

export interface TransportationCompanyResponse {
  transportationCompany: {
    zipcode: string;
  };
}

type FlashTypes = 'success' | 'alert' | 'error' | 'warn';

/**
 * The shape of flash messages passed into react components from the server.
 */
export type RubyFlashMessage = [FlashTypes, string];

/**
 * The shape of flash messages after storing them into the store.
 */
export interface IFlashMesssage {
  text: string;
  type: FlashTypes;
}

export type ToastActions = 'refresh' | 'view ride' | 'success';

export interface ToastNotification {
  action?: ToastActions;
  rideId?: number;
  text: string;
}

export interface DeleteRide {
  body: {
    additionalCancelationInfo: string;
    cancelationReason: string;
    sendNotifications: boolean;
  };
  rideId: number;
}

export interface SubmitRideResponse {
  additionalInfo?: string;
  declinedAt?: Date;
  eta?: Date;
  reason?: string;
  rideId?: number;
}

export interface RideTime {
  outboundDateTime: Date;
  outboundTimeType: string;
  returnDateTime: Date;
  returnTimeType: string;
  rideId?: number;
  willCall: boolean;
}

export interface UnsafeSSRData {
  global: {
    currentUser: User;
    environment: 'development' | 'production' | 'test';
    flash: RubyFlashMessage[];
    isDriver: boolean;
    token?: string | null;
    transportationCompanyId: number;
  };
}

export type GlobalSSRData = Window &
  typeof globalThis & { _unsafeSSRData: UnsafeSSRData };
