import React, { useMemo } from 'react';

import type { CellComponent } from '@/features/RidesTable/types';
import type { CcRideStatusBadges } from '@/types';

import Cell from '@/features/RidesTable/components/Table/Cell';
import RideStatusBadge from '@/features/RideStatusBadge';
import {
  CC_RIDE_STATUS_BADGE_TEXT,
  CC_STYLES,
  ICONS,
} from '@/features/RideStatusBadge/types';

const RideStatusBadgeCell: CellComponent = ({ ride, href }) => {
  const { name } = useMemo(
    () => ({
      name: ride.riderFullName.split(' ').join('-').toLowerCase(),
    }),
    [ride],
  );

  const style = CC_STYLES[ride.statusBadge];
  const Icon = ICONS[ride.statusBadge];
  const SubIcon = null;
  let subtext = null;

  const displayTooltip = false;

  return (
    <Cell
      label="Status"
      tdClass="time-remaining -overflow"
      href={typeof href === 'function' ? href(ride) : href}
      anchorClass="py-8"
    >
      <RideStatusBadge.Root color={style}>
        <RideStatusBadge.Content id={`${name}-bid-status-message`}>
          {Icon && <Icon />}
          {CC_RIDE_STATUS_BADGE_TEXT[ride.statusBadge as CcRideStatusBadges]}
        </RideStatusBadge.Content>

        {displayTooltip && (
          <RideStatusBadge.Tooltip>
            <p>
              You can claim this trip prior to others in the area for a set
              period of time
            </p>
          </RideStatusBadge.Tooltip>
        )}

        {subtext && (
          <RideStatusBadge.Subtext>
            {/* {SubIcon && <SubIcon />} {subtext} */}
            {subtext}
          </RideStatusBadge.Subtext>
        )}
      </RideStatusBadge.Root>
    </Cell>
  );
};

export default RideStatusBadgeCell;
