import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useSubmitRideResponseMutation } from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushToastNotification } from '@/store/toastNotificationsSlice';
import { DECLINE_REASONS_TEXT } from '@/types';

import Modal from '../QuickActionCell/Modal';

const BulkReleaseModal: React.FC<{
  hide: () => void;
  rideIds: number[];
  show: boolean;
}> = ({ rideIds, hide, show }) => {
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [selectedReason, setSelectedReason] = useState('');

  const dispatch = useDispatch();
  const { removeRideById, deselectAllRides } = useTableContext();

  const [submitRideResponse] = useSubmitRideResponseMutation();

  const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setSelectedReason(event?.target?.value);
  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setAdditionalInfo(event.target.value);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submitPromises = rideIds.map((rideId) => {
      const body = {
        declinedAt: new Date(),
        rideId,
        reason: selectedReason,
        additionalInfo,
      };

      return submitRideResponse(body)
        .unwrap()
        .then(() => {
          removeRideById(rideId);
        })
        .catch(() => {
          dispatch(
            pushFlashMessage({
              text: `Sorry, ride ${rideId} cannot be released at the moment. Please try again in a few minutes.`,
              type: 'error',
            }),
          );
        });
    });

    try {
      await Promise.all(submitPromises);

      hide();
      dispatch(
        pushToastNotification({
          text: `${rideIds.length} rides successfully released back to Community.`,
          action: 'success',
        }),
      );
      deselectAllRides();
      setAdditionalInfo('');
      setSelectedReason('');
    } catch (error) {
      dispatch(
        pushFlashMessage({
          text: 'An error occurred while releasing rides. Please try again.',
          type: 'error',
        }),
      );
    }
  };

  return (
    <Modal
      open={show}
      hide={hide}
      title="Release selected rides"
      subtitle="The reason you select will be applied to all rides being released."
    >
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="field mt-1">
          <label htmlFor="reason" className="label">
            Reason <span className="required">*</span>
          </label>
          <div className="dropdown-wrapper">
            <select
              data-test="reason"
              id="reason"
              name="reason"
              value={selectedReason}
              onChange={handleReasonChange}
              className="dropdown"
              required
            >
              <option value="">Select a reason</option>
              {Object.entries(DECLINE_REASONS_TEXT).map(([key, text]) => (
                <option key={key} value={key}>
                  {text}
                </option>
              ))}
            </select>
          </div>

          <label
            htmlFor="additional_info"
            className="label"
            style={{ marginTop: '20px' }}
          >
            Additional information
          </label>
          <textarea
            id="additional_info"
            name="additional_info"
            value={additionalInfo}
            onChange={handleAdditionalInfoChange}
            className="input -tall"
          />
        </div>

        <div className="alert-dialog-actions">
          <button
            type="button"
            className="button -medium -inverted-blue"
            onClick={hide}
          >
            Nevermind
          </button>

          <button
            data-test="cancel-ride-submit-btn"
            type="submit"
            className="button -medium"
          >
            Release ride
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default BulkReleaseModal;
