/* eslint-disable import/prefer-default-export */
import type { RideTime } from '../../types';

import { roundtripBaseApiV3 } from '../../lib/rtk-query';

const createRideResponseBody = (
  params: Partial<{
    id: number;
    outboundDatetime: Date;
    outboundTimeType: string;
    returnDatetime: Date;
    returnTimeType: string;
    willCall: boolean;
  }>,
) => {
  const body: Record<string, RideTime> = {};
  if (params.rideId) body.id = params.id;
  if (params.outboundDatetime) body.outboundDatetime = params.outboundDatetime;
  if (params.outboundTimeType) body.outboundTimeType = params.outboundTimeType;
  if (params.returnDatetime) body.returnDatetime = params.returnDatetime;
  if (params.returnTimeType) body.returnTimeType = params.returnTimeType;
  if (params.willCall) body.willCall = params.willCall;
  return body;
};

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    updateRideTime: build.mutation<void, RideTime>({
      query: (params) => ({
        url: `rides/${params.id}`,
        method: 'PUT',
        body: createRideResponseBody(params),
      }),
    }),
  }),
});

export const { useUpdateRideTimeMutation } = extendedApi;
