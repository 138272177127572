import type { RideStatusQueryParams } from '@/types';
import type { RideFilterState } from '../types';

import { useGetRidesQuery } from '@/api';

const defaultParams: Partial<RideFilterState> = {
  hospitalIds: [],
  items: 10,
  page: 1,
  radius: null,
  vehicleTypes: [],
  willCall: null,
};

const useRideCount = (
  status: RideStatusQueryParams,
  search: string,
  endTime: string,
  startTime: string,
) => {
  const { data: { totalCount: count } = { totalCount: 0 } } = useGetRidesQuery({
    ...defaultParams,
    ...{ startTime, endTime, search },
    ...status,
  } as RideFilterState);

  return { count };
};

export default useRideCount;
