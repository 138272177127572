import {
  CheckIcon,
  Cross2Icon,
  DotIcon,
  MinusIcon,
} from '@radix-ui/react-icons';

export enum RideStatusBadgeText {
  ASSIGNED = 'Assigned',
  AVAILABLE = 'Available',
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
  DECLINED = 'Declined',
  IN_PROGRESS = 'In Progress',
  NEW_RIDE = 'New Ride',
  NOT_STARTED = 'Not Started',
  PREFERRED = 'Preferred',
  PROCESSING = 'Processing',
  RESPONDED = 'Responded',
  SUBMIT_ETA = 'Submit ETA',
}

/* eslint-disable camelcase */
export const CC_RIDE_STATUS_BADGE_TEXT = {
  canceled: 'Canceled',
  completed: 'Completed',
  brokered: 'Brokered',
  on_hold: 'On Hold',
  will_call: 'Will Call',
  incomplete: 'Incomplete',
  requested: 'Requested',
  finding_driver: 'Finding Driver',
  claimed: 'Claimed',
  finding_providers: 'Finding Providers',
  checking_availability: 'Checking Availability',
  at_pickup: 'At Pickup',
  to_pickup: 'To Pickup',
  at_destination: 'At Destination',
  to_destination: 'To Destination',
} as const;
/* eslint-enable camelcase */

export const COLORS = {
  deepRed: '-deep-red',
  green: '-green',
  grey: '-grey',
  orange: '-orange',
  newRide: '-yellow',
  preferred: '-aqua tooltip',
  processing: '-processing d-inline-flex',
  red: '-red',
  yellow: '-yellow',
} as const;

export type Colors = (typeof COLORS)[keyof typeof COLORS];

type DispatcherStatusBadgeStyles = {
  [key in RideStatusBadgeText]: Colors;
};

export const STYLES: DispatcherStatusBadgeStyles = {
  Assigned: COLORS.green,
  Available: COLORS.green,
  Canceled: COLORS.orange,
  Completed: COLORS.green,
  Declined: COLORS.deepRed,
  'In Progress': COLORS.green,
  'New Ride': COLORS.yellow,
  'Not Started': COLORS.grey,
  Preferred: COLORS.preferred,
  Processing: COLORS.processing,
  Responded: COLORS.grey,
  'Submit ETA': COLORS.orange,
} as const;

/* eslint-disable camelcase */
export const CC_STYLES = {
  canceled: COLORS.red,
  completed: COLORS.green,
  brokered: COLORS.yellow,
  on_hold: COLORS.orange,
  will_call: COLORS.grey,
  incomplete: COLORS.orange,
  requested: COLORS.orange,
  finding_driver: COLORS.green,
  claimed: COLORS.green,
  finding_providers: COLORS.green,
  checking_availability: COLORS.green,
  at_pickup: COLORS.green,
  to_pickup: COLORS.green,
  at_destination: COLORS.green,
  to_destination: COLORS.green,
} as const;
/* eslint-enable camelcase */

type DispatcherStatusBadgeIcons = {
  [key in RideStatusBadgeText]: typeof DotIcon | null;
};

export const ICONS: DispatcherStatusBadgeIcons = {
  Assigned: null,
  Available: DotIcon,
  Canceled: null,
  Completed: null,
  Declined: Cross2Icon,
  'In Progress': null,
  'New Ride': null,
  'Not Started': MinusIcon,
  Preferred: null,
  Processing: null,
  Responded: CheckIcon,
  'Submit ETA': null,
} as const;
