import { useMemo } from 'react';

import type { ExcludibleColumns, Role } from '@/types';
import type { RideColumn } from '../types';

import { useSelector } from 'react-redux';

import { selectUserColumnPreferences } from '../store/userColumnPreferencesSlice';

type Pages = {
  admin: 'scheduled';
  care_coordinator: 'scheduled';
  dispatcher: 'community' | 'assigned';
};

const useUserPreferredColumns = <T extends Role>(
  columns: RideColumn[],
  role: T,
  page: Pages[T],
) => {
  const userPreferences = useSelector(selectUserColumnPreferences);

  const filteredColumns = useMemo(() => {
    return columns.filter((column) => {
      const hideKey = column?.hideKey;
      const excludedColumns =
        userPreferences?.[role]?.[page]?.columnsToExcludeFromRendering;

      if (excludedColumns && hideKey) {
        return !Object.values(excludedColumns)?.includes(hideKey);
      }

      return true;
    });
  }, [userPreferences, page, role, columns]);

  return filteredColumns;
};

export default useUserPreferredColumns;
