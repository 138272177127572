/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';

import type { UserColumnPreferencesState } from '@/features/RidesTable/store/userColumnPreferencesSlice';
import type { ExcludibleColumns } from '@/types';

import { LayoutIcon } from '@radix-ui/react-icons';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { keysToCamel } from '@root/util/CaseConvert';
import { useDispatch, useSelector } from 'react-redux';

import {
  useCreateUserPrefsMutation,
  useGetUserPrefsQuery,
  useUpdateUserPrefsMutation,
} from '@/api/rides/getUserPreferences';
import { useAuth } from '@/contexts/AuthProvider';
import {
  selectUserColumnPreferences,
  setDispatcherColumnPreferences,
} from '@/features/RidesTable/store/userColumnPreferencesSlice';
import { error } from '@/lib/@datadog/browser-logs';
import * as Popover from '@/lib/@radix-ui/react-popover';
import { isCommunity } from '@/path_defs';
import { selectToken } from '@/store/authSlice';

import CheckboxItem from './CheckboxItem';

// Empty object on purpose because everything is nullable
const dashboardColumnPreferences: Partial<
  UserColumnPreferencesState['userPreference']['dashboardColumnPreferences']
> = {
  dispatcher: {
    assigned: {
      columnsToExcludeFromRendering: [],
    },
    community: {
      columnsToExcludeFromRendering: [],
    },
  },
};

const EditColumns = () => {
  const dispatch = useDispatch();
  const pageName = isCommunity() ? 'community' : 'assigned';
  const token = useSelector(selectToken);
  const selectedPreferences = useSelector(selectUserColumnPreferences);
  const { currentUser } = useAuth();
  const userId = currentUser?.id;

  const initialExcludedColumns =
    selectedPreferences?.[currentUser.role as 'dispatcher']?.[pageName]
      ?.columnsToExcludeFromRendering;

  const [excludedColumnPreferences, setExcludedColumnPreferences] = useState(
    initialExcludedColumns || [],
  );

  const { data, status, isLoading, isUninitialized, refetch } =
    useGetUserPrefsQuery(userId, {
      skip: !token,
    });

  useEffect(() => {
    if (pageName && !isUninitialized) {
      refetch()
        .then((_) => null)
        .catch((_) => null);
    }
  }, [pageName, refetch]);

  useEffect(() => {
    if (data?.userPreference && !isLoading) {
      const columnsToExclude =
        data.userPreference.dashboardColumnPreferences?.dispatcher?.[pageName]
          ?.columnsToExcludeFromRendering;

      if (columnsToExclude) {
        setExcludedColumnPreferences(Object.values(columnsToExclude));
      }

      dispatch(
        setDispatcherColumnPreferences(
          data?.userPreference?.dashboardColumnPreferences?.dispatcher,
        ),
      );
    }
  }, [data, isLoading, dispatch]);

  const [createUserPrefs, result] = useCreateUserPrefsMutation();

  useEffect(() => {
    if (status !== QueryStatus.fulfilled) {
      return;
    }

    if (!result.error && !data?.userPreference?.id && result.isUninitialized) {
      createUserPrefs({
        dashboardColumnPreferences,
      })
        .unwrap()
        .then((value) => {
          const { userPreference } = keysToCamel(
            value,
          ) as UserColumnPreferencesState;

          const columnsToExclude =
            userPreference?.dashboardColumnPreferences?.[
              currentUser.role as 'dispatcher'
            ]?.[pageName]?.columnsToExcludeFromRendering;

          setExcludedColumnPreferences(columnsToExclude || []);
        })
        .catch((err: string) => error(err));
    }
  }, [
    isLoading,
    selectedPreferences,
    result,
    status,
    createUserPrefs,
    dispatch,
  ]);

  function updateColumnsToExclude(columnName: ExcludibleColumns) {
    if (!Array.isArray(excludedColumnPreferences)) {
      setExcludedColumnPreferences([columnName[0]]);
      return;
    }

    const index = excludedColumnPreferences.indexOf(columnName[0]);

    if (index !== -1) {
      const updatedPreferences = [...excludedColumnPreferences];
      updatedPreferences.splice(index, 1);
      setExcludedColumnPreferences(updatedPreferences);
    } else {
      setExcludedColumnPreferences([
        ...excludedColumnPreferences,
        columnName[0],
      ]);
    }
  }

  const [updateUserPrefsMutation] = useUpdateUserPrefsMutation();

  const handleClose = () => {
    // Merge deeply nested array
    const prefs = {
      dashboardColumnPreferences: {
        ...selectedPreferences,
        [currentUser.role]: {
          ...selectedPreferences?.[currentUser.role],
          [pageName]: {
            ...selectedPreferences?.[currentUser.role as 'dispatcher']?.[
              pageName
            ],
            columnsToExcludeFromRendering: excludedColumnPreferences,
          },
        },
      },
    };

    updateUserPrefsMutation({ userId, userPreference: prefs })
      .unwrap()
      .then((res) => {
        const { userPreference } = keysToCamel(
          res,
        ) as UserColumnPreferencesState;

        dispatch(
          setDispatcherColumnPreferences(
            userPreference?.dashboardColumnPreferences?.[
              currentUser.role as 'dispatcher'
            ],
          ),
        );
      })
      .catch((err: string) => error(err));
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label t-grey-50 px-2">
            Edit Columns
            <LayoutIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="end"
            onCloseAutoFocus={handleClose}
          >
            <CheckboxItem
              text="Vehicle"
              checked={
                (Array.isArray(excludedColumnPreferences) &&
                  !excludedColumnPreferences.includes('vehicle')) ||
                (typeof excludedColumnPreferences === 'object' &&
                  excludedColumnPreferences !== null &&
                  Object.keys(excludedColumnPreferences).length === 0)
              }
              onCheckedChange={() => updateColumnsToExclude(['vehicle'])}
            />
            <CheckboxItem
              text="Book Time"
              checked={
                (Array.isArray(excludedColumnPreferences) &&
                  !excludedColumnPreferences.includes('book_time')) ||
                (typeof excludedColumnPreferences === 'object' &&
                  excludedColumnPreferences !== null &&
                  Object.keys(excludedColumnPreferences).length === 0)
              }
              onCheckedChange={() => updateColumnsToExclude(['book_time'])}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default EditColumns;
