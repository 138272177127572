/* eslint-disable no-param-reassign */
import type { RootState } from '@/store';
import type { ExcludibleColumns, Role } from '@/types';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type Pages = {
  admin: 'scheduled';
  care_coordinator: 'scheduled';
  dispatcher: 'community' | 'assigned';
};

export interface UserColumnPreferencesState {
  userPreference: {
    dashboardColumnPreferences: {
      [r in Role]?: {
        [p in Pages[r]]?: {
          columnsToExcludeFromRendering?: ExcludibleColumns | null;
        };
      };
    };
    id?: number;
  };
}

const initialState: UserColumnPreferencesState = {
  userPreference: {
    dashboardColumnPreferences: {
      dispatcher: {
        assigned: {},
        community: {},
      },
    },
  },
};

const slice = createSlice({
  name: 'userColumnPreferenceFilters',
  initialState,
  reducers: {
    setDispatcherColumnPreferences: (
      state,
      action: PayloadAction<
        Partial<
          UserColumnPreferencesState['userPreference']['dashboardColumnPreferences']['dispatcher']
        >
      >,
    ) => {
      state.userPreference.dashboardColumnPreferences.dispatcher = {
        ...state.userPreference.dashboardColumnPreferences.dispatcher,
        ...action.payload,
      };
    },
  },
});

export const selectUserColumnPreferences = (state: RootState) =>
  state.userColumnPreferences.userPreference.dashboardColumnPreferences;

export const { setDispatcherColumnPreferences } = slice.actions;

export const userColumnPreferences = slice.reducer;
